import { useEffect } from "react"
import { createGlobalState } from "react-hooks-global-state"

const initialState = { isDarkmode: false }
const { useGlobalState } = createGlobalState(initialState)

export const useThemeMode = () => {
  const [isDarkMode, setIsDarkMode] = useGlobalState("isDarkmode")

  useEffect(() => {
    // It will be light by default
    if (localStorage.theme === "dark") {
      toDark()
    } else {
      toLight()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toDark = () => {
    setIsDarkMode(true)
    const root = document.querySelector("html")
    if (!root) return
    !root.classList.contains("dark") && root.classList.add("dark")
    localStorage.theme = "dark"
  }

  const toLight = () => {
    setIsDarkMode(false)
    const root = document.querySelector("html")
    if (!root) return
    root.classList.remove("dark")
    localStorage.theme = "light"
  }

  function _toogleDarkMode() {
    if (localStorage.theme === "light") {
      toDark()
    } else {
      toLight()
    }
  }

  return {
    isDarkMode,
    toDark,
    toLight,
    _toogleDarkMode,
  }
}
