import React, { useEffect, useRef } from "react"

type Props = {
  id: string
  placeholderDefault: string
  fileValue: File | null
  setFileValue: (file: File | null) => void
}

function FileInput({ id, placeholderDefault, fileValue, setFileValue }: Props) {
  const inputRef = useRef<HTMLInputElement>(null)

  const commonStyles =
    "border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200/50 dark:border-neutral-500 dark:focus:ring-primary-500/30 dark:bg-neutral-900"

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0]
    if (file) {
      setFileValue(file)
    }
  }

  const handleClick = () => {
    inputRef.current?.click()
  }

  useEffect(() => {
    console.log({ fileValue })
  }, [fileValue])

  return (
    <div className="relative">
      <input
        id={`${id}-text`}
        type="text"
        readOnly
        value={fileValue?.name ?? placeholderDefault}
        onClick={handleClick}
        style={{ paddingLeft: "140px" }}
        className={`block w-full text-base font-normal h-11 px-4 py-3 rounded-lg cursor-pointer bg-white ${commonStyles} 
                ${!fileValue?.name && "text-neutral-400"}`}
      />
      <button
        id={`${id}-button`}
        onClick={handleClick}
        className={`absolute inset-y-0 left-0 whitespace-nowrap text-center text-base font-normal px-4 py-3 rounded-lg rounded-r-none cursor-pointer bg-neutral-200 dark:bg-neutral-500`}
      >
        Izaberite fajl
      </button>
      <input
        id={`${id}-file`}
        type="file"
        ref={inputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
    </div>
  )
}

export default FileInput
