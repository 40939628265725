import Label from "components/Label/Label"
import Select from "components/Select/Select"
import { IApplicant } from "models/views/applicant"
import { Dispatch, SetStateAction } from "react"

type Props = {
    required?: boolean
    applicant: IApplicant
    setApplicant: Dispatch<SetStateAction<IApplicant>>
}

function SkillRatingInMobileApps({
    required = false,
    applicant,
    setApplicant,
}: Props) {
    return (
        <>
            <Label>
                Ocenite Vaše snalaženje u mobilnim aplikacijama{" "}
                {required && <span className="text-red-500">*</span>}
            </Label>
            <Select
                value={applicant.skillRatingInMobileApps}
                onChange={(e) => {
                    const value = +e.target.value

                    setApplicant((prev) => ({
                        ...prev,
                        skillRatingInMobileApps: value,
                    }))
                }}
                className="mt-1"
            >
                {[10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map((x, i) => (
                    <option defaultValue={applicant.skillRatingInMobileApps} key={i} value={x}>
                        {x}
                    </option>
                ))}
            </Select>
        </>
    )
}

export default SkillRatingInMobileApps
