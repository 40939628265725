import { configureStore } from "@reduxjs/toolkit"
import applicantReducer from "./slices/applicantSlice"
import companyReducer from "./slices/companySlice"
import vehicleReducer from "./slices/vehicleSlice"
import cityReducer from "./slices/citySlice"

export const store = configureStore({
  reducer: {
    company: companyReducer,
    vehicle: vehicleReducer,
    applicant: applicantReducer,
    city: cityReducer,
  },
})

// Infer the type of makeStore
export type AppStore = typeof store
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore["getState"]>
export type AppDispatch = AppStore["dispatch"]
