import Label from "components/Label/Label"
import { IApplicant } from "models/views/applicant"
import { Dispatch, SetStateAction } from "react"
import { toast } from "react-toastify"
import FileInput from "components/FileInput/FileInput"

const placeholderDefault = "Nema izabranog fajla"

type IdFrontProps = {
  applicant: IApplicant
  setApplicant: Dispatch<SetStateAction<IApplicant>>
}

function IdFront({ applicant, setApplicant }: IdFrontProps) {
  return (
    <>
      <Label>Prednja strana lične karte</Label>
      <FileInput
        id="id-front-page-input"
        placeholderDefault={placeholderDefault}
        fileValue={applicant.personalIdFrontPhotoFile}
        setFileValue={(file: File | null) => {
          if (!file) {
            return
          }

          const fileSizeInBytes = file.size

          let fileSizeInMB = fileSizeInBytes / (1024 * 1024)

          fileSizeInMB = +fileSizeInMB.toFixed(2)

          if (fileSizeInMB > 32) {
            toast.error("Maksimalna dozvoljena veličina fajla je 32 MB.")

            return
          }

          setApplicant((prev) => ({
            ...prev,
            personalIdFrontPhotoFile: file,
          }))
        }}
      />
    </>
  )
}

export default IdFront
