import { useAppDispatch, useAppSelector } from "api/hooks"
import { getCities } from "api/slices/citySlice"
import axios from "axios"
import { useEffect } from "react"

function useGetCities() {
  const dispatch = useAppDispatch()

  const { cities } = useAppSelector((x) => x.city)

  useEffect(() => {
    const cancelToken = axios.CancelToken.source()

    dispatch(getCities(cancelToken.token))

    return () => {
      cancelToken.cancel()
    }
  }, [dispatch])

  return {
    cities,
  }
}

export default useGetCities
