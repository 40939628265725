import React from "react"
import Logo from "components/Logo/Logo"
import SocialsList1 from "components/SocialsList1/SocialsList1"
import { CustomLink } from "data/types"
import NcLink from "components/NcLink/NcLink"

export interface WidgetFooterMenu {
  id: string
  title: string
  menus: CustomLink[]
}

// const widgetMenus: WidgetFooterMenu[] = [
//   {
//     id: "5",
//     title: "Getting started",
//     menus: [
//       { href: "/", label: "Installation" },
//       { href: "/", label: "Release Notes" },
//       { href: "/", label: "Upgrade Guide" },
//       { href: "/", label: "Browser Support" },
//       { href: "/", label: "Editor Support" },
//     ],
//   },
//   {
//     id: "1",
//     title: "Explore",
//     menus: [
//       { href: "/", label: "Design features" },
//       { href: "/", label: "Prototyping" },
//       { href: "/", label: "Design systems" },
//       { href: "/", label: "Pricing" },
//       { href: "/", label: "Customers" },
//     ],
//   },
//   {
//     id: "2",
//     title: "Resources",
//     menus: [
//       { href: "/", label: "Best practices" },
//       { href: "/", label: "Support" },
//       { href: "/", label: "Developers" },
//       { href: "/", label: "Learn design" },
//       { href: "/", label: "What's new" },
//     ],
//   },
//   {
//     id: "4",
//     title: "Community",
//     menus: [
//       { href: "/", label: "Discussion Forums" },
//       { href: "/", label: "Code of Conduct" },
//       { href: "/", label: "Community Resources" },
//       { href: "/", label: "Contributing" },
//       { href: "/", label: "Concurrent Mode" },
//     ],
//   },
// ]

const Footer: React.FC = () => {
  return (
    <div className="nc-Footer relative py-4 lg:py-8 border-t border-neutral-200 dark:border-neutral-700">
      <div className="px-4 flex justify-around">
        <div className="flex flex-column justify-center">
          <Logo />
        </div>

        <SocialsList1 className="flex flex-column justify-center" />

        <div className="hidden md:flex text-sm items-center space-x-2">
          <div>Design & Development</div>
          <div>-</div>
          <NcLink href="mailto:pavle.vlajic@outlook.com" className="text-link">
            Pavle Vlajic
          </NcLink>
        </div>
      </div>

      <div className="block md:hidden text-sm flex items-center space-x-2 justify-center mt-8 mb-4">
        <div>Design & Development</div>
        <div>-</div>
        <NcLink href="mailto:pavle.vlajic@outlook.com" className="text-link">
          Pavle Vlajic
        </NcLink>
      </div>
    </div>
  )
}

export default Footer
