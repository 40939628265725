import { sendApplicantRequest } from "api/slices/applicantSlice"
import { useAppDispatch } from "api/hooks"
import Button from "components/Button/Button"
import Heading from "components/Heading/Heading"
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor"
import SectionBecomeAnAuthor2 from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor2"
import SectionHero2 from "components/Sections/SectionHero2"
import useGetCompanies from "hooks/company/useGetCompanies"
import useSmoothScroll from "hooks/useSmoothScroll"
import useGetVehicles from "hooks/vehicle/useGetVehicles"
import { IApplicant } from "models/views/applicant"
import { useEffect, useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Validation } from "types/validation"
import BirthDate from "./components/BirthDate"
import ChooseCompanies from "./components/ChooseCompanies"
import Email from "./components/Email"
import FirstName from "./components/FirstName"
import HowDidYouHearAboutUs from "./components/HowDidYouHearAboutUs"
import IdFront from "./components/IdFront"
import IdRear from "./components/IdRear"
import LastName from "./components/LastName"
import PhoneNumber from "./components/PhoneNumber"
import Rent from "./components/Rent"
import SkillRatingInMobileApps from "./components/SkillRatingInMobileApps"
import Vehicle from "./components/Vehicle"
import WorkedAsDelivererBefore from "./components/WorkedAsDelivererBefore"
import WouldBeOnlyJob from "./components/WouldBeOnlyJob"
import "./styles.css"
import useGetCities from "hooks/city/useGetCities"
import City from "./components/City"

const initialApplicant: IApplicant = {
  firstName: "",
  lastName: "",
  email: "",
  birthDate: "",
  phoneNumber: "",
  companyIds: [],
  skillRatingInMobileApps: 1,
  workedAsDelivererBefore: true,
  wouldBeOnlyJob: true,
  vehicleId: null,
  rentId: null,
  howDidYouHearAboutUs: null,
  personalIdFrontPhotoFile: null,
  personalIdRearPhotoFile: null,
  cityId: "",
}

export const scrollToFormTop = () => {
  const element = document.getElementById("prijava")

  if (element) {
    element.scrollIntoView({ behavior: "smooth" })
  }
}

const initialValidation: Validation = {
  workedAsDelivererBefore: "",
  companiesEmpty: "",
  firstNameEmpty: "",
  lastNameEmpty: "",
  phoneNumberEmpty: "",
  birthDateEmpty: "",
  emailEmpty: "",
  cityEmpty: "",
}

function HomePage() {
  useSmoothScroll(80)
  const dispatch = useAppDispatch()
  const { companies, setCompanies } = useGetCompanies()
  const { vehicles, rents } = useGetVehicles()
  const { cities } = useGetCities()

  const [applicant, setApplicant] = useState<IApplicant>(initialApplicant)

  const [triggeredValidation, setTriggeredValidation] = useState<boolean>(false)

  const [validation, setValidation] = useState<Validation>(initialValidation)

  useEffect(() => {
    console.log({ applicant })
  }, [applicant])

  useEffect(() => {
    console.log({ validation })
  }, [validation])

  const submit = async () => {
    const promise = toast.promise(dispatch(sendApplicantRequest(applicant)), {
      pending: "Vaša prijava se šalje...",
      success: "Prijava je uspešno poslata!",
      error: "Dogodila se greška prilikom slanja prijave.",
    })

    await promise

    // if (response.payload) {
    //   const result = response.payload as IApiResponse
    // }
  }

  const handleSubmit = async () => {
    const isValid =
      validation.workedAsDelivererBefore === "" &&
      validation.companiesEmpty === "" &&
      validation.firstNameEmpty === "" &&
      validation.lastNameEmpty === "" &&
      validation.phoneNumberEmpty === "" &&
      validation.birthDateEmpty === "" &&
      validation.emailEmpty === "" &&
      validation.cityEmpty === ""

    if (triggeredValidation) {
      if (isValid) {
        await submit()
      } else {
        scrollToFormTop()
      }
    } else {
      if (isValid) {
        await submit()
      } else {
        setTriggeredValidation(true)
        scrollToFormTop()
      }
    }
  }

  return (
    <div id="pocetna" className="nc-PageHomeDemo4 relative">
      <div className="relative">
        <SectionHero2 />
      </div>

      <div
        id="o-nama"
        className="px-8 md:container pt-20 pb-20 md:pb-20 md:pb-20"
      >
        {/*mt-20 mb-0 md:mb-20 */}
        <SectionBecomeAnAuthor />
      </div>

      <div
        id="postanite-dostavljac"
        className="px-8 bg-neutral-200 bg-opacity-40 dark:bg-black dark:bg-opacity-20 pt-10 pb-0 md:pb-20 md:pt-20"
      >
        {/*mt-20 mb-0 md:mb-20 */}
        <SectionBecomeAnAuthor2 />
      </div>

      <div
        id="prijava"
        className="px-8 md:container relative overflow-hidden pt-20"
      >
        {/*mt-20*/}
        <Heading
          desc="Popunite našu kratku prijavu ispod i započnite svoju avanturu sa nama. 
            Potrebno je samo nekoliko minuta da unesete svoje osnovne informacije i postanete 
            deo našeg rastućeg tima."
          isCenter
        >
          Prijavite se sada!
        </Heading>
        <div className="relative mt-20">
          <div className="rounded-xl md:p-6">
            <div className="grid md:grid-cols-2 gap-8">
              <label className="block">
                <WorkedAsDelivererBefore
                  required
                  applicant={applicant}
                  setApplicant={setApplicant}
                />
              </label>

              <label className="block">
                <City
                  required
                  cities={cities}
                  applicant={applicant}
                  setApplicant={setApplicant}
                  triggeredValidation={triggeredValidation}
                  validation={validation}
                  setValidation={setValidation}
                />
              </label>

              <label className="block">
                <ChooseCompanies
                  required
                  applicant={applicant}
                  setApplicant={setApplicant}
                  companies={companies}
                  setCompanies={setCompanies}
                  triggeredValidation={triggeredValidation}
                  validation={validation}
                  setValidation={setValidation}
                />
              </label>

              <label className="block">
                <WouldBeOnlyJob
                  required
                  applicant={applicant}
                  setApplicant={setApplicant}
                />
              </label>

              <label className="block">
                <Vehicle
                  vehicles={vehicles}
                  applicant={applicant}
                  setApplicant={setApplicant}
                />
              </label>

              <label className="block">
                <Rent
                  rents={rents}
                  applicant={applicant}
                  setApplicant={setApplicant}
                />
              </label>

              <label className="block">
                <FirstName
                  required
                  applicant={applicant}
                  setApplicant={setApplicant}
                  triggeredValidation={triggeredValidation}
                  validation={validation}
                  setValidation={setValidation}
                />
              </label>

              <label className="block">
                <LastName
                  required
                  applicant={applicant}
                  setApplicant={setApplicant}
                  triggeredValidation={triggeredValidation}
                  validation={validation}
                  setValidation={setValidation}
                />
              </label>

              <label className="block">
                <PhoneNumber
                  required
                  applicant={applicant}
                  setApplicant={setApplicant}
                  triggeredValidation={triggeredValidation}
                  validation={validation}
                  setValidation={setValidation}
                />
              </label>

              <label className="block">
                <BirthDate
                  required
                  applicant={applicant}
                  setApplicant={setApplicant}
                  triggeredValidation={triggeredValidation}
                  validation={validation}
                  setValidation={setValidation}
                />
              </label>

              <label className="block md:col-span-2">
                <Email
                  required
                  applicant={applicant}
                  setApplicant={setApplicant}
                  triggeredValidation={triggeredValidation}
                  validation={validation}
                  setValidation={setValidation}
                />
              </label>

              <label className="block md:col-span-2">
                <SkillRatingInMobileApps
                  required
                  applicant={applicant}
                  setApplicant={setApplicant}
                />
              </label>

              <label className="block">
                <IdFront applicant={applicant} setApplicant={setApplicant} />
              </label>

              <label className="block">
                <IdRear applicant={applicant} setApplicant={setApplicant} />
              </label>

              <label className="block md:col-span-2">
                <HowDidYouHearAboutUs
                  applicant={applicant}
                  setApplicant={setApplicant}
                />
              </label>
            </div>
          </div>

          <div className="flex justify-center mt-10 mb-10 md:mb-20">
            <Button
              onClick={handleSubmit}
              className="text-xl md:text-lg my-5"
              pattern="primary"
            >
              Pošalji prijavu
            </Button>
          </div>
        </div>
      </div>

      <ToastContainer
        position="bottom-center"
        autoClose={8000}
        hideProgressBar
        bodyClassName="custom-toast-body"
      />
    </div>
  )
}

export default HomePage
