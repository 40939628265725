import { FC } from "react"

export interface CheckboxProps {
    label?: string
    subLabel?: string
    name: string
    inputClasses?: string
    className?: string
    checked?: boolean
    onChange?: any
}

const Checkbox: FC<CheckboxProps> = ({
    subLabel = "",
    label = "",
    name,
    inputClasses = "",
    className = "",
    checked = false,
    onChange
}) => {
    return (
        <div className={`flex items-start w-fit cursor-pointer ${className}`}>
            <div className="flex items-center h-5">
                <input
                    id={name}
                    name={name}
                    checked={checked}
                    onChange={onChange}
                    type="checkbox"
                    className={`cursor-pointer focus:ring-action-primary h-4 w-4 text-primary border-primary ${inputClasses}`}
                />
            </div>
            {label && (
                <div className="ml-3 text-sm">
                    <label
                        htmlFor={name}
                        className="cursor-pointer text-paragraph-small text-black dark:text-white"
                    >
                        {label}
                    </label>
                    {subLabel && (
                        <p className="text-neutral-500 cursor-pointer">
                            {subLabel}
                        </p>
                    )}
                </div>
            )}
        </div>
    )
}

export default Checkbox
