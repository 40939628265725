import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { IApiResponse } from "models/shared/apiResponse"
import { IApplicant } from "models/views/applicant"
import axiosInstance from "../axiosInstance"

type State = {
    applicant: IApplicant | null
}

const initialState: State = {
    applicant: null,
}

export const sendApplicantRequest = createAsyncThunk(
    "Applicant/Send_Applicant_Request",
    async (request: IApplicant) => {
        try {
            const formData = new FormData()

            // Append each field to the FormData object
            formData.append("firstName", request.firstName)
            formData.append("lastName", request.lastName)
            formData.append("email", request.email)
            formData.append("birthDate", request.birthDate)
            formData.append("phoneNumber", request.phoneNumber)
            for (const x of request.companyIds) {
                formData.append("companyIds", x)
            }
            formData.append(
                "skillRatingInMobileApps",
                request.skillRatingInMobileApps.toString()
            )
            formData.append(
                "workedAsDelivererBefore",
                request.workedAsDelivererBefore.toString()
            )
            formData.append("wouldBeOnlyJob", request.wouldBeOnlyJob.toString())
            formData.append("vehicleId", request.vehicleId || "")
            formData.append("rentId", request.rentId || "")
            formData.append("cityId", request.cityId || "")
            formData.append(
                "howDidYouHearAboutUs",
                request.howDidYouHearAboutUs || ""
            )

            if (request.personalIdFrontPhotoFile) {
                formData.append(
                    "personalIdFrontPhotoFile",
                    request.personalIdFrontPhotoFile
                )
            }

            if (request.personalIdRearPhotoFile) {
                formData.append(
                    "personalIdRearPhotoFile",
                    request.personalIdRearPhotoFile
                )
            }

            const result = (await axiosInstance.post(
                "/email/send-applicant-request",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data", // Set the content type to FormData
                    },
                }
            )) as IApiResponse

            return result
        } catch (error) {
            throw error
        }
    }
)

const applicantSlice = createSlice({
    name: "applicant",
    initialState,
    reducers: {},
    extraReducers: (builder) => {},
})

export default applicantSlice.reducer
