import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { CancelToken } from "axios"
import { IVehicle } from "models/views/vehicle"
import axiosInstance from "../axiosInstance"
import { IApiResponse } from "models/shared/apiResponse"

type State = {
    vehicles: IVehicle[]
}

const initialState: State = {
    vehicles: [],
}

export const getVehicles = createAsyncThunk(
    "Vehicle/Get_Vehicles",
    async (cancelToken: CancelToken) => {
        const result = (await axiosInstance.get("/vehicle/get-vehicles", {
            cancelToken: cancelToken,
        })) as IApiResponse<IVehicle[]>

        return result
    }
)

const vehicleSlice = createSlice({
    name: "vehicle",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getVehicles.fulfilled, (state, action) => {
            const { data } = action.payload
            state.vehicles = data.map((x) => ({ ...x, _selected: false }))
        })
    },
})

export default vehicleSlice.reducer
