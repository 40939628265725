import HomePage from "app/(home)/page"
import SiteHeader from "app/SiteHeader"
import Page404 from "app/not-found"
import Footer from "components/Footer/Footer"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { Page } from "./types"

export const pages: Page[] = [{ path: "/", component: HomePage }]

const MyRoutes = () => {
  return (
    <BrowserRouter>
      <SiteHeader />

      <Routes>
        {pages.map(({ component: Component, path }, index) => {
          return <Route key={index} element={<Component />} path={path} />
        })}
        <Route element={<Page404 />} />
      </Routes>

      <Footer />
    </BrowserRouter>
  )
}

export default MyRoutes
