import Input from "components/Input/Input"
import Label from "components/Label/Label"
import { IApplicant } from "models/views/applicant"
import { Dispatch, SetStateAction } from "react"

type Props = {
    applicant: IApplicant
    setApplicant: Dispatch<SetStateAction<IApplicant>>
}

function HowDidYouHearAboutUs({ applicant, setApplicant }: Props) {
    return (
        <>
            <Label>Kako ste došli do nas?</Label>
            <Input
                value={applicant.howDidYouHearAboutUs ?? ""}
                onChange={(e) => {
                    setApplicant((prev) => ({
                        ...prev,
                        howDidYouHearAboutUs: e.target.value,
                    }))
                }}
                type="text"
                className="mt-1 rounded-lg"
            />
        </>
    )
}

export default HowDidYouHearAboutUs
