import Header2 from "components/Header/Header2"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"

const SiteHeader = () => {
  let pathname = useLocation().pathname

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [pathname])

  return <Header2 />
}
export default SiteHeader
