import { getCompanies } from "api/slices/companySlice"
import { useAppDispatch, useAppSelector } from "api/hooks"
import axios from "axios"
import { ICompany } from "models/views/company"
import { useEffect, useState } from "react"

function useGetCompanies() {
    const dispatch = useAppDispatch()

    const { companies: companyData } = useAppSelector((x) => x.company)
    const [companies, setCompanies] = useState<ICompany[]>([])

    useEffect(() => {
        const cancelToken = axios.CancelToken.source()

        dispatch(getCompanies(cancelToken.token))

        return () => {
            cancelToken.cancel()
        }
    }, [dispatch])

    useEffect(() => {
        setCompanies(companyData)
    }, [companyData])

    return {
        companies,
        setCompanies,
    }
}

export default useGetCompanies
