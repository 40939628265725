import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { CancelToken } from "axios"
import { ICompany } from "models/views/company"
import axiosInstance from "../axiosInstance"
import { IApiResponse } from "models/shared/apiResponse"

type State = {
    companies: ICompany[]
}

const initialState: State = {
    companies: [],
}

export const getCompanies = createAsyncThunk(
    "Company/Get_Companies",
    async (cancelToken: CancelToken) => {
        const result = (await axiosInstance.get("/company/get-companies", {
            cancelToken: cancelToken,
        })) as IApiResponse<ICompany[]>

        return result
    }
)

const companySlice = createSlice({
    name: "company",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCompanies.fulfilled, (state, action) => {
            const { data } = action.payload
            state.companies = data.map((x) => ({ ...x, _selected: false }))
        })
    },
})

export default companySlice.reducer
