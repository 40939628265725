import Label from "components/Label/Label"
import Select from "components/Select/Select"
import { IApplicant } from "models/views/applicant"
import { Dispatch, SetStateAction } from "react"

type Props = {
    required?: boolean
    applicant: IApplicant
    setApplicant: Dispatch<SetStateAction<IApplicant>>
}

function WouldBeOnlyJob({ applicant, setApplicant, required }: Props) {
    return (
        <>
            <Label>
                Da li bi vam ovo bio jedini ili dodatni posao?{" "}
                {required && <span className="text-red-500">*</span>}
            </Label>
            <Select
                value={applicant.wouldBeOnlyJob ? "Jedini" : "Dodatni"}
                onChange={(e) =>
                    setApplicant((prev) => ({
                        ...prev,
                        wouldBeOnlyJob: e.target.value === "Jedini",
                    }))
                }
                className="mt-1"
            >
                <option selected value={"Jedini"}>
                    Jedini
                </option>
                <option value={"Dodatni"}>Dodatni</option>
            </Select>
        </>
    )
}

export default WouldBeOnlyJob
