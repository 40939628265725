import { useAppDispatch, useAppSelector } from "api/hooks"
import { getVehicles } from "api/slices/vehicleSlice"
import axios from "axios"
import { useEffect } from "react"

function useGetVehicles() {
  const dispatch = useAppDispatch()

  const { vehicles } = useAppSelector((x) => x.vehicle)

  useEffect(() => {
    const cancelToken = axios.CancelToken.source()

    dispatch(getVehicles(cancelToken.token))

    return () => {
      cancelToken.cancel()
    }
  }, [dispatch])

  return {
    vehicles,
    rents: vehicles.filter((x) => x.isRentable),
  }
}

export default useGetVehicles
