import Checkbox from "components/Checkbox/Checkbox"
import Label from "components/Label/Label"
import { IApplicant } from "models/views/applicant"
import { ICompany } from "models/views/company"
import { Dispatch, SetStateAction, useEffect } from "react"
import { Validation } from "types/validation"

type Props = {
    companies: ICompany[]
    setCompanies: Dispatch<SetStateAction<ICompany[]>>
    required?: boolean
    applicant: IApplicant
    setApplicant: Dispatch<SetStateAction<IApplicant>>
    triggeredValidation: boolean
    validation: Validation
    setValidation: Dispatch<SetStateAction<Validation>>
}

function ChooseCompanies({
    companies,
    setCompanies,
    required = false,
    applicant,
    setApplicant,
    triggeredValidation,
    validation,
    setValidation,
}: Props) {
    const handleOnChange = (companyId: string) => {
        const updatedCompanies = companies.map((y) => {
            if (y.id === companyId) {
                return { ...y, _selected: !y._selected }
            }
            return y
        })

        setCompanies([...updatedCompanies])

        setApplicant((prev) => ({
            ...prev,
            companyIds: updatedCompanies
                .filter((c) => c._selected)
                .map((c) => c.id),
        }))
    }

    const validate = () => {
        console.log("validate companies")

        if (applicant.companyIds.length === 0) {
            setValidation((prev) => ({
                ...prev,
                companiesEmpty: "Odabir kompanije je obavezan.",
            }))
        } else {
            setValidation((prev) => ({
                ...prev,
                companiesEmpty: "",
            }))
        }
    }

    useEffect(() => {
        validate()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicant.companyIds])

    return (
        <>
            <Label>
                Kompanija za koju konkurišete?{" "}
                {required && <span className="text-red-500">*</span>}
            </Label>
            {companies.map((x, i) => (
                <Checkbox
                    key={i}
                    name={x.name}
                    label={x.name}
                    checked={x._selected}
                    onChange={() => handleOnChange(x.id)}
                    inputClasses="rounded-md"
                    className="my-2"
                />
            ))}

            {triggeredValidation && (
                <>
                    {validation.companiesEmpty.length > 0 && (
                        <span className="text-red-500 text-sm">
                            {validation.companiesEmpty}
                        </span>
                    )}
                </>
            )}
        </>
    )
}

export default ChooseCompanies
