import photo from "images/girl.png"
import { FC } from "react"
import Image from "../Image"

export interface SectionBecomeAnAuthorProps {
  className?: string
}

const SectionBecomeAnAuthor: FC<SectionBecomeAnAuthorProps> = ({
  className = "",
}) => {
  return (
    <div
      className={`nc-SectionBecomeAnAuthor relative flex flex-col lg:flex-row items-center ${className}`}
    >
      <div className="flex-shrink-0 mb-0 md:mb-14 lg:mb-0 lg:mr-10 lg:w-2/5">
        <span className="text-xs uppercase tracking-wider font-medium text-neutral-400">
          Prilike koje čekaju
        </span>
        <h2 className="font-semibold text-3xl sm:text-4xl mt-3">
          Zašto raditi sa nama?
        </h2>
        <span className="block mt-8 text-neutral-500 dark:text-neutral-400">
          Otkrijte fleksibilnost i slobodu rada kao dostavljač.
          Pridružite se našem timu i uživajte u konkurentnim zaradama,
          fleksibilnom radnom vremenu i prilikama za napredovanje. Naša agencija
          Vam pruža podršku na svakom koraku, osiguravajući da Vaše iskustvo kao
          dostavljač bude bezbrižno i produktivno.
        </span>
      </div>
      <div className="flex-grow hidden md:block">
        <Image alt="hero" sizes="(max-width: 768px) 100vw, 50vw" src={photo} />
      </div>
    </div>
  )
}

export default SectionBecomeAnAuthor
