import image1 from "images/front.jpg"
import glovoImage from "images/glovo_logo.png"
import woltImage from "images/wolt_logo.png"
import { FC } from "react"
import { useNavigate } from "react-router-dom"
import Button from "../Button/Button"

export interface SectionHero2Props {}
const SectionHero2: FC<SectionHero2Props> = () => {
  const navigate = useNavigate()

  return (
    <div className="relative h-screen">
      <img
        src={image1}
        alt="Landing Page Background"
        className="object-cover w-full h-full"
      />

      <div className="absolute inset-0 flex justify-center flex-col text-white p-4 bg-opacity-40 bg-black">
        <div className="md:container md:px-24">
          <div className="md:max-w-[33rem]">
            <h1 className="text-[2rem] leading-[2.5rem] md:text-5xl md:leading-[1.2] font-bold">
              Želite li da postanete dostavljač?
            </h1>

            <div className="flex space-x-4">
              <img
                src={woltImage}
                alt="Wolt"
                className="w-[80px] md:w-[100px]"
              />
              <img
                src={glovoImage}
                alt="Glovo"
                className="w-[80px] md:w-[100px]"
              />
            </div>

            <div className="flex space-x-4">
              <Button
                className="text-xl md:text-lg"
                pattern="primary"
                onClick={() => navigate("#prijava")}
              >
                Aplicirajte
              </Button>
              <Button
                className="text-xl md:text-lg"
                pattern="white"
                onClick={() => navigate("#o-nama")}
              >
                O nama
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionHero2
