import React, { FC } from "react"
import NavigationItem from "./NavigationItem"
import { NAVIGATION_DEMO_3 } from "data/navigation"

interface Props {
  className?: string
}

const Navigation: FC<Props> = ({ className = "flex" }) => {
  return (
    <ul className={`nc-Navigation items-center ${className}`}>
      {NAVIGATION_DEMO_3.map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}
    </ul>
  )
}

export default Navigation
