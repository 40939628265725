import photo from "images/guy.png"
import { FC } from "react"
import Image from "../Image"
import Button from "components/Button/Button"
import { useNavigate } from "react-router-dom"

export interface SectionBecomeAnAuthor2Props {
  className?: string
}

const SectionBecomeAnAuthor2: FC<SectionBecomeAnAuthor2Props> = ({
  className = "",
}) => {
  const navigate = useNavigate()
  return (
    <div className="md:container relative py-16">
      <div
        className={`nc-SectionBecomeAnAuthor relative flex flex-col lg:flex-row items-center ${className}`}
      >
        <div className="flex-grow hidden md:block">
          <Image
            alt="hero"
            sizes="(max-width: 768px) 100vw, 50vw"
            src={photo}
          />
        </div>
        <div className="flex-shrink-0 mb-0 md:mb-14 lg:mb-0 lg:mr-10 lg:w-2/5">
          <span className="text-xs uppercase tracking-wider font-medium text-neutral-400">
            Put do uspeha
          </span>
          <h2 className="font-semibold text-3xl sm:text-4xl mt-3">
            Kako postati dostavljač?
          </h2>
          <span className="block mt-8 text-neutral-500 dark:text-neutral-400">
            Postati dostavljač je jednostavno. Prvi korak je popunjavanje naše
            online prijave. Nakon toga, naš tim će vas kontaktirati kako biste
            završili proces registracije i dobili sve potrebne informacije. Ne
            zahtevamo prethodno iskustvo, ali cenimo vašu posvećenost i
            pouzdanost. Kao dostavljač, vaš zadatak će biti brza i sigurna
            dostava hrane i drugih proizvoda do naših klijenata. Pružamo vam
            obuku, savete za efikasnu dostavu i podršku tokom celog radnog
            vremena.
          </span>
          <Button
            className="text-xl md:text-lg mt-8"
            pattern="primary"
            onClick={() => navigate("#prijava")}
          >
            Aplicirajte
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SectionBecomeAnAuthor2
