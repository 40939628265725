import Image from "components/Image"
import Link from "components/Link"
import logo from "images/logo_circle.png"

const Logo = () => {
  return (
    <Link
      href="/"
      className="ttnc-logo inline-block text-primary-6000 flex-shrink-0"
    >
      <Image src={logo} width={70} />
    </Link>
  )
}

export default Logo
