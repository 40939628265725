import axios from "axios"

const apiEnvironment = {
  local: "https://localhost:7128",
  production: "https://api.ecodostava.com",
}

const axiosInstance = axios.create({
  baseURL: apiEnvironment.production + "/api",
})

export default axiosInstance

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers["Time-Zone"] =
      Intl.DateTimeFormat().resolvedOptions().timeZone

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    return Promise.reject(error)
  }
)
