import Input from "components/Input/Input"
import Label from "components/Label/Label"
import { IApplicant } from "models/views/applicant"
import { Dispatch, SetStateAction, useEffect } from "react"
import { Validation } from "types/validation"

type Props = {
    required?: boolean
    applicant: IApplicant
    setApplicant: Dispatch<SetStateAction<IApplicant>>
    validation: Validation
    setValidation: Dispatch<SetStateAction<Validation>>
    triggeredValidation: boolean
}

function Email({
    required = false,
    applicant,
    setApplicant,
    validation,
    setValidation,
    triggeredValidation,
}: Props) {
    const validate = () => {
        if (applicant.email.trim().length === 0) {
            setValidation((prev) => ({
                ...prev,
                emailEmpty: "Email adresa je obavezna.",
            }))
        } else {
            setValidation((prev) => ({
                ...prev,
                emailEmpty: "",
            }))
        }
    }

    useEffect(() => {
        validate()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicant.email])

    return (
        <>
            <Label>
                Email adresa{" "}
                {required && <span className="text-red-500">*</span>}
            </Label>
            <Input
                value={applicant.email}
                onChange={(e) => {
                    setApplicant((prev) => ({
                        ...prev,
                        email: e.target.value,
                    }))
                }}
                type="text"
                className="mt-1 rounded-lg"
            />
            {triggeredValidation && (
                <>
                    {validation.emailEmpty.length > 0 && (
                        <span className="text-red-500 text-sm">
                            {validation.emailEmpty}
                        </span>
                    )}
                </>
            )}
        </>
    )
}

export default Email
