import Label from "components/Label/Label"
import Select from "components/Select/Select"
import { IApplicant } from "models/views/applicant"
import { IVehicle } from "models/views/vehicle"
import { Dispatch, SetStateAction } from "react"

type Props = {
    rents: IVehicle[]
    applicant: IApplicant
    setApplicant: Dispatch<SetStateAction<IApplicant>>
}

function Rent({ rents, applicant, setApplicant }: Props) {
    const handleOnChange = (rentId: string) => {
        setApplicant((prev) => ({
            ...prev,
            rentId: rentId.length === 0 ? null : rentId,
        }))
    }

    return (
        <>
            <Label>Renta?</Label>
            <Select
                value={applicant.rentId ?? "Ne"}
                onChange={(e) => handleOnChange(e.target.value)}
                className="mt-1"
            >
                <option selected value={"Ne"}>
                    Ne
                </option>

                {rents.map((x, i) => (
                    <option key={i} value={x.id}>
                        {x.name}
                    </option>
                ))}
            </Select>
        </>
    )
}

export default Rent
