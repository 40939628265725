import Input from "components/Input/Input"
import Label from "components/Label/Label"
import { IApplicant } from "models/views/applicant"
import { Dispatch, SetStateAction, useEffect } from "react"
import { Validation } from "types/validation"

type Props = {
  required?: boolean
  applicant: IApplicant
  setApplicant: Dispatch<SetStateAction<IApplicant>>
  validation: Validation
  setValidation: Dispatch<SetStateAction<Validation>>
  triggeredValidation: boolean
}

function PhoneNumber({
  required = false,
  applicant,
  setApplicant,
  validation,
  setValidation,
  triggeredValidation,
}: Props) {
  const validate = () => {
    console.log("phone validation")
    if (applicant.phoneNumber.trim().length === 0) {
      setValidation((prev) => ({
        ...prev,
        phoneNumberEmpty: "Broj telefona je obavezan.",
      }))
    } else {
      setValidation((prev) => ({
        ...prev,
        phoneNumberEmpty: "",
      }))
    }
  }

  useEffect(() => {
    validate()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicant.phoneNumber])

  return (
    <>
      <Label>
        Broj telefona {required && <span className="text-red-500">*</span>}
      </Label>
      <Input
        value={applicant.phoneNumber}
        onChange={(e) => {
          setApplicant((prev) => ({
            ...prev,
            phoneNumber: e.target.value,
          }))
        }}
        type="text"
        className="mt-1 rounded-lg"
      />
      {triggeredValidation && (
        <>
          {validation.phoneNumberEmpty.length > 0 && (
            <span className="text-red-500 text-sm">
              {validation.phoneNumberEmpty}
            </span>
          )}
        </>
      )}
    </>
  )
}

export default PhoneNumber
