import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { CancelToken } from "axios"
import { ICity } from "models/views/city"
import axiosInstance from "../axiosInstance"
import { IApiResponse } from "models/shared/apiResponse"

type State = {
  cities: ICity[]
}

const initialState: State = {
  cities: [],
}

export const getCities = createAsyncThunk(
  "City/Get_Cities",
  async (cancelToken: CancelToken) => {
    const result = (await axiosInstance.get("/city/get-cities", {
      cancelToken: cancelToken,
    })) as IApiResponse<ICity[]>

    return result
  }
)

const citySlice = createSlice({
  name: "city",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCities.fulfilled, (state, action) => {
      const { data } = action.payload
      state.cities = data
    })
  },
})

export default citySlice.reducer
