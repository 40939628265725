import Label from "components/Label/Label"
import Select from "components/Select/Select"
import { IApplicant } from "models/views/applicant"
import { Dispatch, SetStateAction } from "react"

type Props = {
    applicant: IApplicant
    setApplicant: Dispatch<SetStateAction<IApplicant>>
    required?: boolean
}

function WorkedAsDelivererBefore({
    applicant,
    setApplicant,
    required = false,
}: Props) {
    return (
        <>
            <Label>
                Da li ste nekada radili kao dostavljač?{" "}
                {required && <span className="text-red-500">*</span>}
            </Label>
            <Select
                className="mt-1"
                value={applicant.workedAsDelivererBefore ? "Da" : "Ne"}
                onChange={(e) =>
                    setApplicant((prev) => ({
                        ...prev,
                        workedAsDelivererBefore: e.target.value === "Da",
                    }))
                }
            >
                <option selected value={"Da"}>
                    Da
                </option>
                <option value={"Ne"}>Ne</option>
            </Select>
        </>
    )
}

export default WorkedAsDelivererBefore
