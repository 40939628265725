import Input from "components/Input/Input"
import Label from "components/Label/Label"
import { IApplicant } from "models/views/applicant"
import { Dispatch, SetStateAction, useEffect } from "react"
import { Validation } from "types/validation"

type Props = {
  required?: boolean
  applicant: IApplicant
  setApplicant: Dispatch<SetStateAction<IApplicant>>
  validation: Validation
  setValidation: Dispatch<SetStateAction<Validation>>
  triggeredValidation: boolean
}

function FirstName({
  required = false,
  applicant,
  setApplicant,
  validation,
  setValidation,
  triggeredValidation,
}: Props) {
  const validate = () => {
    if (applicant.firstName.trim().length === 0) {
      setValidation((prev) => ({
        ...prev,
        firstNameEmpty: "Ime je obavezno.",
      }))
    } else {
      setValidation((prev) => ({
        ...prev,
        firstNameEmpty: "",
      }))
    }
  }

  useEffect(() => {
    validate()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicant.firstName])

  return (
    <>
      <Label>Ime {required && <span className="text-red-500">*</span>}</Label>
      <Input
        value={applicant.firstName}
        onChange={(e) => {
          setApplicant((prev) => ({
            ...prev,
            firstName: e.target.value,
          }))
        }}
        type="text"
        className="mt-1 rounded-lg"
      />
      {triggeredValidation && (
        <>
          {validation.firstNameEmpty.length > 0 && (
            <span className="text-red-500 text-sm">
              {validation.firstNameEmpty}
            </span>
          )}
        </>
      )}
    </>
  )
}

export default FirstName
