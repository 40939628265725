import Label from "components/Label/Label"
import Select from "components/Select/Select"
import { IApplicant } from "models/views/applicant"
import { IVehicle } from "models/views/vehicle"
import { Dispatch, SetStateAction } from "react"

type Props = {
  vehicles: IVehicle[]
  applicant: IApplicant
  setApplicant: Dispatch<SetStateAction<IApplicant>>
}

function Vehicle({ vehicles, applicant, setApplicant }: Props) {
  const handleOnChange = (vehicleId: string) => {
    setApplicant((prev) => ({
      ...prev,
      vehicleId: vehicleId.length === 0 ? null : vehicleId,
    }))
  }

  return (
    <>
      <Label>Rad sopstvenim vozilom?</Label>
      <Select
        value={applicant.vehicleId ?? "Nemam sopstveni prevoz"}
        onChange={(e) => handleOnChange(e.target.value)}
        className="mt-1"
      >
        <option selected value={"Nemam sopstveni prevoz"}>
          Nemam sopstveni prevoz
        </option>

        {vehicles.map((x, i) => (
          <option key={i} value={x.id}>
            {x.name}
          </option>
        ))}
      </Select>
    </>
  )
}

export default Vehicle
